import $ from 'jquery'

class Multiselect {

  constructor(args) {
    const self = this;
    const {
      selector,
      filter,
      exclusions,
    } = args;
    if (!$(selector)) {
      console.error("ERROR: Element %s does not exist.", selector);
      return;
    }

    this.selector = selector;
    this.exclusions = exclusions || [];
    this.filter = filter;
    this.events();
    $(`${selector} input`).each(function (e) {
      $(this).prop('checked', true)
      const value = $(this).val();
      if (self.exclusions.indexOf(value) > -1) {
        $(this).prop('checked', false)
      }
    });
    this.setCount();
  }

  open(that) {
    const target = $(that).parent().attr("data-target");
    $(this.selector + ".multiselect").toggleClass("active");
  }

  close() {
    $(this.selector + ".multiselect").removeClass("active");
  }

  events() {
    const self = this;

    $(document).on('click', e => {
      if (!$(this.selector).is(e.target) && $(this.selector).has(e.target).length === 0) {
        this.close();
      }
    });

    $(document).on("click", this.selector + ".multiselect > .multiselect-title", function (e) {
      setTimeout(function () {
        self.open();
      }, 10)
    });

    $(document).on("click", this.selector + ".multiselect .filter-link", function (e) {
      self.filter(self.exclusions);
    });

    $(this.selector + ' input').on("change", function (e) {
      const value = $(this).val();
      if (!$(this).prop('checked')) {
        self.exclusions.push(value)
      } else {
        self.exclusions = self.exclusions.filter(e => e !== value)
      }
      self.setCount();
    });
  }

  setCount() {
    let i = 0;
    $(this.selector + ' input').each(function (e) {
      if ($(this).prop('checked')) {
        i += 1;
      }
    });

    $(this.selector + ' .count').text(i)
  }

}

window.Multiselect = Multiselect
